<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- 回頂端 -->
    <a id="toTop" class="toTop icon iconButton" onclick="toTop()">
      <font-awesome-icon icon="fa-solid fa-chevron-up"></font-awesome-icon>
    </a>
    <!-- Main -->
    <section id="main" class="main store">
      <a class="back icon iconButton third" @click.prevent="goBack()">
        <font-awesome-icon icon="fa-solid fa-chevron-left"></font-awesome-icon>
      </a>
      <!-- <img class="cover" :src="store.bannerImageUrl" /> -->
      <img class="cover" src="@/assets/img/ticket-cover.png" />
      <!-- <img class="profile" src="@/assets/img/ticket-profile.png" /> -->
      <!-- class: active 切換模式 -->
      <!-- <a class="favorite flexV active">
        <div class="icon iconButton">
          <font-awesome-icon
            icon="fa-solid fa-heart"
            sieze="xl"
            class="error"
          ></font-awesome-icon>
        </div>
        <div class="icon iconButton">
          <font-awesome-icon
            icon="fa-regular fa-heart"
            size="xl"
            class="minor"
          ></font-awesome-icon>
        </div>
      </a> -->
      <span class="fL">{{storeInfo.name}}</span>
      <span class="fM gray">{{storeInfo.categoryName}}</span>
      <!-- Tabs -->
      <div class="tabs line fM rounded">
        <!-- <a class="tab">
          <span>票券</span>
        </a>
        <div class="lineV"></div> -->
        <a class="tab active">
          <span>集章</span>
        </a>
        <div class="lineV"></div>
        <a class="tab" @click.prevent="$router.push(storeInfoLink)">
          <span>店家資訊</span>
        </a>
      </div>
      <!-- 集章列表 -->
      <a
        class="stampsItem flexV around"
        v-for="(stampCard, index) in stampCards"
        :key="'stampCard' + index"
        @click.prevent="$router.push(`/stamp-cards/${stampCard.stampCardId}`)"
      >
        <span class="fL third single">{{ stampCard.stampCardName }}</span>
        <div class="topLeft">
          <div class="textBg flexV center">
            <!-- class: active 切換開關 -->
            <span class="fM white">已完成</span>
            <!-- 已完成/已過期 -->
          </div>
        </div>
        <div class="topRight">
          <!-- <div class="textBg flexV center active">
            <span class="fM primary">最新</span>
          </div> -->
        </div>
        <span class="fM single">{{ stampCard.storeName }}</span>
        <span class="fS gray"
          >集章 {{ tsToDate(stampCard.collectStartTime) }} -
          {{ tsToDate(stampCard.collectEndTime) }}</span
        >
        <span class="fS gray"
          >兌換 {{ tsToDate(stampCard.redeemStartTime) }} -
          {{ tsToDate(stampCard.redeemEndTime) }}</span
        >
        <div class="flexH width" v-if="stampCard.collectedAt">
          <img :src="stampCard.stampImageUrl || defaultStampImage" />
          <div class="bar flexH width rounded">
            <div
              :style="{ width: calProgress(stampCard) + '%' }"
            ></div>
          </div>
          <span class="fM bold primary"
            >{{stampCard.numOfStamps}}/{{ stampCard.stampCardMaxStamps }}</span
          >
        </div>
      </a>
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      storeApiHost: process.env.VUE_APP_STORE_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      stampCards: [],
      storeId: null,
      storeInfoLink: "",
      storeInfo: {},
      store: {},
      defaultStampImage: "@/assets/img/stamp.png"
    };
  },
  mounted() {
    this.storeId = this.$route.params.id;
    this.storeInfoLink = `/stores/${this.storeId}/info`
    this.getStampCards();
    this.getStoreInfo();
  },
  computed: {
    ...mapState(["user", "userInfo"]),
  },
  methods: {
    dummyStoreInfo() {
      let result = {
        name: "測試商店",
        address: "測試地址",
        phone: "0987654321",
        banId: "85111267",
        storePlaceId: "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
        geometry: JSON.stringify({
          location: {
            lat: 25.0109536,
            lng: 121.2173832,
          },
          viewport: {
            south: 25.0100204697085,
            west: 121.2164427697085,
            north: 25.0127184302915,
            east: 121.2191407302915,
          },
        }),
        categoryName: "餐飲",
        categoryId: 1,
        addressComponents: JSON.stringify([
          {
            long_name: "9",
            short_name: "9",
            types: ["street_number"],
          },
          {
            long_name: "航站南路",
            short_name: "航站南路",
            types: ["route"],
          },
          {
            long_name: "大園區",
            short_name: "大園區",
            types: ["administrative_area_level_3", "political"],
          },
          {
            long_name: "桃園市",
            short_name: "桃園市",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "台灣",
            short_name: "TW",
            types: ["country", "political"],
          },
          {
            long_name: "33758",
            short_name: "33758",
            types: ["postal_code"],
          },
        ]),
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetStoreInfoAPI() {
      let config = {
        url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/stores/${this.storeId}`,
        methods: 'GET'
      };
      return this.$http(config);
    },
    getStoreInfo() {
      return this.callGetStoreInfoAPI()
      // return this.dummyStoreInfo()
      .then((res) => {
        this.storeInfo = res.data;
        return;
      });
    },
    dummyStampCards() {
      let result = [
        {
            "storeId": 1,
            "storeName": "美又美早餐店桃園店", //特店名稱
            "storeCategoryId": 1, //特店分類：1是餐飲業、2是旅宿業
            "storeAddress": "桃園市桃園區縣府路1號",
            "storePhone": null,
            "stampCardId": "WJZbm9bgEv",
            "stampCardName": "集點卡", //集章標題
            "stampCardDesc": "集滿十點，下次消費九五折",
            "status": "normal",
            "bannerImageUrl": "https://cdn.pixabay.com/photo/2020/07/08/04/07/sea-5382487_960_720.jpg",
            "stampCardMaxStamps": 20, //最大集章數
            "collectStartTime": 1653235200, //集章開始時間
            "collectEndTime": 1656604499, //集章截止時間
            "redeemStartTime": 1654444800, //兌換開始時間
            "redeemEndTime": 1658073599, //兌換截止時間
            "createdAt": 1653806706,
            "numOfStamps": 7, //會員當前集章數，如無此field，則會員尚未持有該卡
            "collectedAt": 1653807421 //會員首次持有此集章卡時間，如無此field，則會員尚未持有該卡
        }
      ];
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callGetStampAPI() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stores/${this.storeId}/stamp-cards?uuid=${this.user.userId}`,
        headers: { }
      };

      return this.$http(config);
    },
    getStampCards() {
      this.callGetStampAPI()
      // this.dummyStampCards()
      .then((res) => {
        console.log("res: ", res);
        this.stampCards = res.data;
        this.store = this.stampCards.length > 0 ? this.stampCards[0] : {};
      });
    },
    tsToDate(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    calProgress(stampCard) {
      if(stampCard.collectedAt) return (stampCard.numOfStamps / stampCard.stampCardMaxStamps) * 100;
      return 0;
    },
    goBack() {
        window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push("/stores");
    },
  },
};
</script>
